<template>
  <h1>Refs</h1>
  <p>A study on refs in vuejs</p>
  <div class="add_pad">
    <input ref="mario" />
    <button v-on:click="myRefFunction">Submit</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const mario = ref(0)
const myRefFunction = () => {
  console.log(mario.value.value)
}

// deprecated way of doing it
// export default {
//   data() {
    
//   },
//   setup() {
//     const mario = ref(0)
//     const myRefFunction = () => {
//       console.log(mario.value.value)
//     }
//   },
//   methods() {
  
//     return { mario, myRefFunction }
//   },
// }
</script >

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

button {
  margin-left: 10px;
}

div * {
  padding: 7px;
}
</style>
